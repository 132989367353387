import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useState, useEffect } from "react";
import InventoryCounts from "./component/Inventory-counts/InventoryCounts";
import InventoryDashboard from "./component/Inventory-Dashboard/inventoryDashboard";
import Warehouse from "./component/Warehouse/warehouse";
import InventoryCharges from "./component/Inventory-charges/InventoryCharges";
import { Route, Routes } from "react-router-dom";
import NotFound from "./component/not-found/not-found";
import UserLayout from "./layout/user-layout/user-layout";
import ProtectedRoute from "./routes/private-routes";
import Login from "./component/login/Login";
import AddInventoryCount from "./component/Inventory-counts/addInventoryCount/addInventoryCount";
import AddInventoryCharge from "./component/Inventory-charges/addInventoryCharge/addInventoryCharge";
import ItemDetails from "./component/Warehouse/WarehouseItemDetails/itemDetails";
import InventoryChargeDetails from "./component/Inventory-charges/InventoryChargeDetail/InventoryChargeDetails";
import Barcode from "./component/barcodeFunctionality/barcode";
import SupportIssues from "./component/Support-Issues/Support-Issues";
import AddSupportIssue from "./component/Support-Issues/AddSupportTicket/AddSupportTIcket";
import SupportTicketDetails from "./component/Support-Issues/SupportTicketDetails/SupportTicketDetails";
import UserContext from "./context/userContext";
import WarehouseContext from "./context/warehouseContext";
import axios from "axios";
import AddBarcodes from "./component/barcodeAddition/addBarcode";
import AddWarehouseMove from "./component/Warehouse/addWarehouseMove/addWareouseMove";

function App() {
  const account = JSON.parse(localStorage.getItem("CanoUserDetails"));
  const [warehouseItemsData, setWarehouseItemsData] = useState([]);
  const [BarcodePanel, seBarcodePanel] = useState([]);
  const [warehouseCode, setWarehouseCode] = useState({
    newWarehouseCode: account?.DefaultWarehouse?.toString(),
    newCompanyCode: account?.DefaultCompany?.toString(),
  });

  useEffect(() => {
    axios
      .get(
        `https://celtic.azurewebsites.net/api/APIv1GetWarehouseItemsByCompany?CanoID=${account?.CanoID}`
      )
      .then((response) => {
        setWarehouseItemsData(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [account?.CanoID]);

  useEffect(() => {
    axios
      .get(
        `https://celtic.azurewebsites.net/api/APIv1GetUserFromEmail?Email=${account?.email}`
      )
      .then((response) => {
        seBarcodePanel(response?.data.BarcodePanel);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [account?.email]);

  const changeDefaultWarehouseApp = (newWarehouseCode, newCompanyCode) => {
    setWarehouseCode({ ...warehouseCode, newWarehouseCode, newCompanyCode });
  };

  return (
    <div className="App">
      <GoogleOAuthProvider clientId="973471171711-2inbv52i7cp9vi7uq9rndoosvo3l4k0n.apps.googleusercontent.com">
        <UserContext.Provider value={account}>
          <WarehouseContext.Provider value={warehouseCode}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <InventoryDashboard
                        warehouseItemsData={warehouseItemsData}
                      />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/inventory-counts"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <InventoryCounts
                        warehouseItemsData={warehouseItemsData}
                      />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/inventory-counts/add-inventory-count"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <AddInventoryCount />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />

              <Route
                path="/warehouse/add-warehouse-move"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <AddWarehouseMove />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/inventory-charges/add-inventory-charge"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <AddInventoryCharge />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/inventory-charges/inventory-charge-item-details"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <InventoryChargeDetails />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/warehouse/warehouse-item-details"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <ItemDetails />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />

              <Route
                path="/warehouse"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <Warehouse warehouseItemsData={warehouseItemsData} />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/barcode"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <Barcode />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/inventory-charges"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <InventoryCharges
                        warehouseItemsData={warehouseItemsData}
                      />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/support-issues"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <SupportIssues warehouseItemsData={warehouseItemsData} />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/support-issues/add-support-issue"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <AddSupportIssue
                        warehouseItemsData={warehouseItemsData}
                      />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/support-issues/ticket-details"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                    barcodeAccess={BarcodePanel}
                  >
                    <ProtectedRoute>
                      <SupportTicketDetails
                        warehouseItemsData={warehouseItemsData}
                        barcodeAccess={BarcodePanel}
                      />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              {BarcodePanel === "Y" ? (
                <Route
                  path="/add-barcode"
                  element={
                    <UserLayout
                      changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                      barcodeAccess={BarcodePanel}
                    >
                      <ProtectedRoute>
                        <AddBarcodes />
                      </ProtectedRoute>
                    </UserLayout>
                  }
                />
              ) : (
                ""
              )}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </WarehouseContext.Provider>
        </UserContext.Provider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
