import React, { useRef, useEffect, useCallback } from "react";
import Quagga from "quagga";

const Scanner = (props) => {
  const videoRef = useRef(null);
  const { isModalOpen } = props;

  const onDetected = useCallback(
    (result) => {
      props.onDetected(result, props);
      Quagga.stop();
    },
    [props]
  );

  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          constraints: {
            width: isModalOpen ? 1280 : 400,
            height: 720,
            facingMode: "environment",
            aspectRatio: { min: 1, max: 2 },
          },
        },
        locator: {
          patchSize: "medium",
          halfSample: true,
        },
        numOfWorkers: 4,
        decoder: {
          readers: [
            "upc_reader",
            // "ean_reader",
            // "ean_8_reader",
            // "code_128_reader",
            "code_39_reader",
            // "upc_reader",
            // "upc_e_reader",
            // // "i2of5_reader",
            // "2of5_reader",
            // "code_93_reader",
            // "codabar_reader",
          ],
        },

        locate: true,
        lastResult: true, // Add the lastResult configuration
      },
      (err) => {
        if (err) {
          Quagga.stop();
          return console.log(err);
        }
        Quagga.start();
      }
    );

    Quagga.onDetected(onDetected);

    // Apply auto zoom
    if (videoRef.current) {
      videoRef.current.style.transform = "scale(1)"; // Adjust the scale value as needed
    }

    return () => {
      Quagga.offDetected(onDetected);
      Quagga.stop();
    };
  }, [onDetected, isModalOpen]);

  return (
    <>
      <div id="interactive" className="viewport">
        {isModalOpen && (
          <video
            ref={videoRef}
            className="drawingBuffer"
            autoPlay
            playsInline
          />
        )}
      </div>
    </>
  );
};

export default Scanner;
