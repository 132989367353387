const iconStyle = {
  fill: "white",
};

const menuItem = [
  {
    path: "/",
    name: "Dashboard",

    icon: (
      <svg
        className="icon-nav"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9.56809 9H3.43176C2.64225 9 2 8.31315 2 7.46866V3.53118C2 2.68685 2.64225 2 3.43176 2H9.56809C10.3578 2 11 2.68685 11 3.53118V7.46866C11 8.31315 10.3578 9 9.56809 9ZM3.43176 3.31249C3.31895 3.31249 3.22727 3.41054 3.22727 3.53118V7.46866C3.22727 7.58946 3.31895 7.68751 3.43176 7.68751H9.56809C9.68105 7.68751 9.77273 7.58946 9.77273 7.46866V3.53118C9.77273 3.41054 9.68105 3.31249 9.56809 3.31249H3.43176Z"
          fill="white"
        />
        <path
          d="M9.56809 22H3.43176C2.64225 22 2 21.3272 2 20.5001V11.5001C2 10.6728 2.64225 10 3.43176 10H9.56809C10.3578 10 11 10.6728 11 11.5001V20.5001C11 21.3272 10.3578 22 9.56809 22ZM3.43176 11.2857C3.31895 11.2857 3.22727 11.3818 3.22727 11.5001V20.5001C3.22727 20.6182 3.31895 20.7143 3.43176 20.7143H9.56809C9.68105 20.7143 9.77273 20.6182 9.77273 20.5001V11.5001C9.77273 11.3818 9.68105 11.2857 9.56809 11.2857H3.43176Z"
          fill="white"
        />
        <path
          d="M20.5682 22H14.4319C13.6422 22 13 21.3132 13 20.4688V16.5313C13 15.6868 13.6422 15 14.4319 15H20.5682C21.3578 15 22 15.6868 22 16.5313V20.4688C22 21.3132 21.3578 22 20.5682 22ZM14.4319 16.3125C14.319 16.3125 14.2273 16.4105 14.2273 16.5313V20.4688C14.2273 20.5895 14.319 20.6875 14.4319 20.6875H20.5682C20.681 20.6875 20.7727 20.5895 20.7727 20.4688V16.5313C20.7727 16.4105 20.681 16.3125 20.5682 16.3125H14.4319Z"
          fill="white"
        />
        <path
          d="M20.5682 14H14.4319C13.6422 14 13 13.3272 13 12.4999V3.49994C13 2.67283 13.6422 2 14.4319 2H20.5682C21.3578 2 22 2.67283 22 3.49994V12.4999C22 13.3272 21.3578 14 20.5682 14ZM14.4319 3.28571C14.319 3.28571 14.2273 3.38176 14.2273 3.49994V12.4999C14.2273 12.6182 14.319 12.7143 14.4319 12.7143H20.5682C20.681 12.7143 20.7727 12.6182 20.7727 12.4999V3.49994C20.7727 3.38176 20.681 3.28571 20.5682 3.28571H14.4319Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    path: "/inventory-charges",
    name: "Warehouse to Job",

    icon: (
      <svg
        className="icon-nav"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <mask
          id="mask0_5857_4484"
          style={iconStyle}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <path d="M0 1.90735e-06H24V24H0V1.90735e-06Z" fill="white" />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 21.892C0 23.0582 0.947706 24 2.11223 24H21.8877C23.0523 24 24 23.0583 24 21.892V21.4338C24 18.7687 21.8335 16.6119 19.1656 16.6119H13.7361V18.0182H19.1656C21.0609 18.0182 22.5937 19.5494 22.5937 21.4338V21.892C22.5937 22.2776 22.2797 22.5938 21.8877 22.5938H2.11223C1.72033 22.5938 1.40625 22.2776 1.40625 21.892V21.4338C1.40625 19.5494 2.93908 18.0182 4.83441 18.0182H10.1813V16.6119H4.83441C2.16646 16.6119 0 18.7687 0 21.4338V21.892Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.42188 16.8643C9.42188 18.2642 10.5596 19.3955 11.9587 19.3955C13.3577 19.3955 14.4955 18.2642 14.4955 16.8643V15.2833H13.0892V16.8643C13.0892 17.4836 12.5851 17.9893 11.9587 17.9893C11.3323 17.9893 10.8281 17.4836 10.8281 16.8643V15.2833H9.42188V16.8643Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.91699 24C5.30532 24 5.62012 23.6852 5.62012 23.2969V21.2824C5.62012 20.894 5.30532 20.5793 4.91699 20.5793C4.52867 20.5793 4.21387 20.894 4.21387 21.2824V23.2969C4.21387 23.6852 4.52867 24 4.91699 24Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.083 24C19.4713 24 19.7861 23.6852 19.7861 23.2969V21.2824C19.7861 20.894 19.4713 20.5793 19.083 20.5793C18.6947 20.5793 18.3799 20.894 18.3799 21.2824V23.2969C18.3799 23.6852 18.6947 24 19.083 24Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9587 14.9541C9.77898 14.9541 8.01562 13.193 8.01562 11.0251V7.12054C8.01562 6.73221 7.70083 6.41741 7.3125 6.41741C6.92417 6.41741 6.60938 6.73221 6.60938 7.12054V11.0251C6.60938 13.9737 9.00637 16.3604 11.9587 16.3604C14.911 16.3604 17.308 13.9737 17.308 11.0251V7.12054C17.308 6.73221 16.9932 6.41741 16.6049 6.41741C16.2165 6.41741 15.9017 6.73221 15.9017 7.12054V11.0251C15.9017 13.193 14.1384 14.9541 11.9587 14.9541Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.41602 20.252V17.3149H7.00977V20.7858C7.00977 21.2696 7.40262 21.6582 7.88272 21.6582H16.1192C16.5993 21.6582 16.9921 21.2695 16.9921 20.7858V17.3149H15.5858V20.252H8.41602Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.14062 7.82324H17.7767V5.80209C17.7767 2.59545 15.1699 -0.000429153 11.9587 -0.000429153C8.74747 -0.000429153 6.14062 2.59545 6.14062 5.80209V7.82324ZM7.54687 6.41699V5.80209C7.54687 3.37613 9.52009 1.40582 11.9587 1.40582C14.3973 1.40582 16.3705 3.37613 16.3705 5.80209V6.41699H7.54687Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.73438 7.12012C4.73438 7.50844 5.04917 7.82324 5.4375 7.82324H18.4799C18.8682 7.82324 19.183 7.50844 19.183 7.12012C19.183 6.73179 18.8682 6.41699 18.4799 6.41699H5.4375C5.04917 6.41699 4.73438 6.73179 4.73438 7.12012Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.26465 5.12934C9.26465 6.61718 10.4708 7.82324 11.9586 7.82324C13.4464 7.82324 14.6525 6.61717 14.6525 5.12934C14.6525 3.64152 13.4464 2.43538 11.9586 2.43538C10.4708 2.43538 9.26465 3.64151 9.26465 5.12934ZM11.9586 6.41699C11.2474 6.41699 10.6709 5.84049 10.6709 5.12934C10.6709 4.41816 11.2474 3.84163 11.9586 3.84163C12.6697 3.84163 13.2463 4.41815 13.2463 5.12934C13.2463 5.8405 12.6697 6.41699 11.9586 6.41699Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9585 3.8418C12.3468 3.8418 12.6616 3.527 12.6616 3.13867V0.70286C12.6616 0.314535 12.3468 -0.000265121 11.9585 -0.000265121C11.5702 -0.000265121 11.2554 0.314535 11.2554 0.70286V3.13867C11.2554 3.527 11.5702 3.8418 11.9585 3.8418Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    path: "/warehouse",
    name: "Warehouse Moves",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M17.5 20C16.1217 20 15 18.8785 15 17.5005V10.8351C15 10.376 14.6267 10.0019 14.1667 10.0019H5.83333C5.37333 10.0019 5 10.376 5 10.8351V17.5005C5 18.8785 3.87833 20 2.5 20C1.12167 20 0 18.8785 0 17.5005V8.10556C0 6.71832 0.686667 5.42773 1.83583 4.65204L7.66917 0.716116C9.085 -0.238705 10.915 -0.238705 12.3308 0.716116L18.1642 4.65204C19.3133 5.42773 20 6.71832 20 8.10556V17.5005C20 18.8785 18.8783 20 17.5 20ZM5.83333 8.33552H14.1667C15.545 8.33552 16.6667 9.45698 16.6667 10.8351V17.5005C16.6667 17.9595 17.04 18.3336 17.5 18.3336C17.96 18.3336 18.3333 17.9595 18.3333 17.5005V8.10556C18.3333 7.27322 17.9217 6.49836 17.2317 6.03345L11.3983 2.09752C10.5483 1.5243 9.45167 1.5243 8.6025 2.09752L2.76833 6.03345C2.07833 6.49836 1.66667 7.27322 1.66667 8.10556V17.5005C1.66667 17.9595 2.04 18.3336 2.5 18.3336C2.96 18.3336 3.33333 17.9595 3.33333 17.5005V10.8351C3.33333 9.45698 4.455 8.33552 5.83333 8.33552ZM9.16667 19.1668V17.5005C9.16667 17.0406 8.79417 16.6673 8.33333 16.6673H7.5C7.03917 16.6673 6.66667 17.0406 6.66667 17.5005V19.1668C6.66667 19.6267 7.03917 20 7.5 20H8.33333C8.79417 20 9.16667 19.6267 9.16667 19.1668ZM9.16667 14.1678V12.5014C9.16667 12.0415 8.79417 11.6682 8.33333 11.6682H7.5C7.03917 11.6682 6.66667 12.0415 6.66667 12.5014V14.1678C6.66667 14.6277 7.03917 15.0009 7.5 15.0009H8.33333C8.79417 15.0009 9.16667 14.6277 9.16667 14.1678ZM13.3333 19.1668V17.5005C13.3333 17.0406 12.9608 16.6673 12.5 16.6673H11.6667C11.2058 16.6673 10.8333 17.0406 10.8333 17.5005V19.1668C10.8333 19.6267 11.2058 20 11.6667 20H12.5C12.9608 20 13.3333 19.6267 13.3333 19.1668Z"
          fill="white"
        />
      </svg>
    ),
  },

  {
    path: "/inventory-counts",
    name: "Inventory Counts",

    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M21 16V7.99999C20.9996 7.64927 20.9071 7.3048 20.7315 7.00116C20.556 6.69751 20.3037 6.44536 20 6.26999L13 2.26999C12.696 2.09446 12.3511 2.00204 12 2.00204C11.6489 2.00204 11.304 2.09446 11 2.26999L4 6.26999C3.69626 6.44536 3.44398 6.69751 3.26846 7.00116C3.09294 7.3048 3.00036 7.64927 3 7.99999V16C3.00036 16.3507 3.09294 16.6952 3.26846 16.9988C3.44398 17.3025 3.69626 17.5546 4 17.73L11 21.73C11.304 21.9055 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9055 13 21.73L20 17.73C20.3037 17.5546 20.556 17.3025 20.7315 16.9988C20.9071 16.6952 20.9996 16.3507 21 16Z"
          stroke="white"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.27002 6.96001L12 12.01L20.73 6.96001"
          stroke="white"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 22.08V12"
          stroke="white"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    path: "/support-issues",
    name: "Support Issues",

    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.16741 3.33333C4.16741 2.87333 4.54081 2.5 5.00089 2.5H6.66786V0.833333C6.66786 0.373333 7.04126 0 7.50134 0C7.96142 0 8.33482 0.373333 8.33482 0.833333V2.5H10.0018C10.4619 2.5 10.8353 2.87333 10.8353 3.33333C10.8353 3.79333 10.4619 4.16667 10.0018 4.16667H8.33482V5.83333C8.33482 6.29333 7.96142 6.66667 7.50134 6.66667C7.04126 6.66667 6.66786 6.29333 6.66786 5.83333V4.16667H5.00089C4.54081 4.16667 4.16741 3.79333 4.16741 3.33333ZM19.3668 10.8458L13.695 17.21C12.1147 18.9833 9.84676 20 7.47217 20H3.33393C1.49527 20 0 18.505 0 16.6667V12.5C0 10.6617 1.49527 9.16667 3.33393 9.16667H10.7169C11.6663 9.16667 12.4989 9.67417 12.9582 10.4317L15.6386 7.48667C16.0904 6.99083 16.7072 6.7 17.3773 6.66917C18.0466 6.63167 18.6883 6.86917 19.1843 7.32083C20.1961 8.24333 20.2778 9.82417 19.3668 10.845V10.8458ZM18.0616 8.55333C17.8957 8.4025 17.679 8.33083 17.4548 8.335C17.2297 8.34583 17.023 8.4425 16.8714 8.60917L13.1824 12.6625C12.8615 13.555 12.0663 14.2383 11.087 14.3783L6.78538 14.9925C6.33113 15.0583 5.90772 14.7417 5.84271 14.2858C5.7777 13.83 6.09442 13.4083 6.5495 13.3433L10.8511 12.7292C11.317 12.6625 11.6688 12.2575 11.6688 11.7867C11.6688 11.2617 11.242 10.835 10.7169 10.835H3.33393C2.4146 10.835 1.66696 11.5825 1.66696 12.5017V16.6683C1.66696 17.5875 2.4146 18.335 3.33393 18.335H7.47217C9.37168 18.335 11.187 17.5217 12.4506 16.1033L18.1224 9.73833C18.4283 9.395 18.4008 8.86417 18.0616 8.55417V8.55333Z"
          fill="white"
        />
      </svg>
    ),
  },
];
export default menuItem;
