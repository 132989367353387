// export default Navbar;
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/userContext";
import InventoryWarehouseService from "../../shared/_services/inventory.service.js";
import { useSnackbar } from "notistack";
import { FaBars } from "react-icons/fa";
import logo from "../../assets/images/dashboardLogo.svg";
import logout from "../../assets/images/log-out.svg";
import settingIcon from "../../assets/images/settings.svg";
import { useMsal } from "@azure/msal-react";
import { Modal } from "react-bootstrap";
import materialIcon from "../../assets/images/Materials.svg";
import { googleLogout } from "@react-oauth/google";

const Navbar = ({ toggleSidebar, changeDefaultWarehouse, barcodeAccess }) => {
  const { instance } = useMsal();
  const account = useContext(UserContext);

  const [isOpen, setIsOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const navigate = useNavigate();

  const handleMaterialButtonClick = (link) => {
    navigate(link);
  };

  const { enqueueSnackbar } = useSnackbar();

  const operatorName = account?.FName.split(" ")[0];

  const [defaultWarehouse, setDefaultWarehouse] = useState(
    account?.DefaultWarehouse + "-.-" + account?.DefaultCompany
  );

  const handleDefaultWarehouseChange = (e) => {
    const newWarehouse = e.target.value;

    setDefaultWarehouse(newWarehouse);
    changeDefaultWarehouse(
      newWarehouse.split("-.-")[0],
      newWarehouse.split("-.-")[1]
    );
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    toggleSidebar(!isOpen);
  };
  const onLogout = () => {
    setShowModal(true);
  };

  const handleConfirmLogout = () => {
    if (account?.auth === "msoft") {
      localStorage.removeItem("CanoUserDetails");
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } else {
      googleLogout();
      localStorage.removeItem("CanoUserDetails");
      window.location.href = "/login";
    }

    setShowModal(false);
  };

  const handleCancelLogout = () => {
    setShowModal(false);
  };

  useEffect(() => {
    InventoryWarehouseService.getWarehouseListByCompany(enqueueSnackbar).then(
      (response) => {
        if (!response.error) {
          setWarehouseList(response.data);
        }
      }
    );
  }, [enqueueSnackbar]);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "20px",
          boxShadow: "#eeeeee 2px 0px 0px 1px",
          position: "relative",
        }}
      >
        <div
          className="top-section"
          style={{
            minWidth: isOpen ? "220px" : "70px",
            display: "flex",
            justifyContent: isOpen ? "flex-start" : "center",
            transition: "all 0.5s",
          }}
        >
          <div className="bars">
            <FaBars onClick={toggle} className="bar" />
          </div>
          <img
            alt="Logo"
            className="logo"
            style={{ display: isOpen ? "block" : "none" }}
            src={logo}
          />
        </div>
        <div className="nav-user">
          <div className="user-welcome">
            <span className="m-0 ">Hi, welcome {operatorName}!</span>
          </div>

          <div className="truck-logout d-flex">
            <select
              className="form-select me-3"
              style={{ border: "1px solid #FF4E00" }}
              value={defaultWarehouse}
              onChange={(e) => handleDefaultWarehouseChange(e)}
            >
              <option>Select Warehouse</option>
              {warehouseList.map((warehouse) => (
                <option
                  value={
                    warehouse.Warehouse_Code.trim() +
                    "-.-" +
                    warehouse.Company_Code
                  }
                  key={warehouse.Warehouse_Code + warehouse.Company_Code}
                >
                  {warehouse.Company_Code + " - " + warehouse.Name}
                </option>
              ))}
            </select>
            {barcodeAccess === "Y" ? (
              <div className="d-flex align-items-center">
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle setting-icon"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={settingIcon} alt="setting" className="pt-1" />
                  </button>

                  <div
                    className="dropdown-menu drop-inner-content p-0 setting-barcode"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      className="dropdown-item"
                      onClick={(event) =>
                        handleMaterialButtonClick("/add-barcode")
                      }
                    >
                      <img src={materialIcon} alt="material" className="pe-2" />
                      Barcode
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <img
              src={logout}
              alt="logout"
              className="icon-color me-4"
              style={{ color: "#FF4E00" }}
              onClick={onLogout}
            />
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCancelLogout}>
        <Modal.Header>
          <h5 style={{ fontWeight: "600", marginBottom: "0!important" }}>
            Sign Out
          </h5>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to sign out?</p>
        </Modal.Body>

        <Modal.Footer>
          <button className="cancel_btn" onClick={handleCancelLogout}>
            Cancel
          </button>

          <button className="conform_btn" onClick={handleConfirmLogout}>
            Sign Out
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Navbar;
