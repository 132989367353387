import React, { useState } from "react";
import Scanner from "./Scanner";

const Barcode = () => {
  const [scanning, setScanning] = useState(false);
  const [results, setResults] = useState([]);

  const scan = () => {
    setScanning(!scanning);
    console.log("scanning", scanning);
  };

  const onDetected = (result) => {
    console.log(parseInt(result.codeResult.code, 10));
    setResults(results.concat([result]));
    setScanning(false);
  };
  console.log(results);
  return (
    <div>
      <button onClick={scan}>{scanning ? "Stop" : "Start"}</button>
      <ul className="results">
        {results.map((result) => {
          return (
            <li>
              {result.codeResult.code} [{result.codeResult.format}]
            </li>
          );
        })}
      </ul>
      {scanning ? <Scanner onDetected={onDetected} /> : null}
    </div>
  );
};

export default Barcode;
