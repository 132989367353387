import React, { useState } from "react";
import Navbar from "../navigation/navigation";
import Sidebar from "../sidebar/sidebar";
import Main from "../main/main";

const UserLayout = ({ children, changeDefaultWarehouseApp, barcodeAccess }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = (isOpen) => {
    setIsOpen(isOpen);
  };

  const changeDefaultWarehouse = (newWarehouse, newCompany) => {
    changeDefaultWarehouseApp(newWarehouse, newCompany);
  };

  return (
    <>
      <Navbar
        toggleSidebar={toggleSidebar}
        isOpen={isOpen}
        changeDefaultWarehouse={changeDefaultWarehouse}
        barcodeAccess={barcodeAccess}
      />
      <div className="wrapper">
        <Sidebar isOpen={isOpen} />
        <Main>{children}</Main>
      </div>
    </>
  );
};

export default UserLayout;
