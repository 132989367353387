import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import SearchIcon from "../../assets/images/Search.svg";
import Constant from "../../shared/_helpers/constants";
import AddIcon from "../../assets/images/add-white.svg";
import ItemIcon from "../../assets/images/user-placeholder.png";
import DataTable from "react-data-table-component";
import UserContext from "../../context/userContext";

import "./support.css";

const InventoryCounts = () => {
  const navigate = useNavigate();
  const account = useContext(UserContext);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const CanoID = account.CanoID;

  const statusCode = {
    closed: "Closed",
    inProgress: "In Progress",
    onHold: "On Hold",
    solved: "Solved",
    Open: "Open",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${Constant.BASE_URL}/api/APIv1GetSupportTicket?CanoID=${CanoID}`
        );
        setUsers(Array.isArray(data) ? data.reverse() : []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [CanoID]);

  // useEffect(() => {
  //   if (search.match("^[a-zA-Z0-9]*$") == null) {
  //     return setFilteredData([]);
  //   }
  //   const resultData = users.filter((user) => {
  //     return user?.Subject?.toLowerCase().match(search.toLowerCase());
  //   });
  //   setFilteredData(resultData);
  // }, [search, users]);

  useEffect(() => {
    if (!search.trim()) {
      // If search is empty, reset filtered data
      setFilteredData([]);
      return;
    }

    const searchWords = search.trim().split(/\s+/); // Split search string into words

    const resultData = users.filter((user) => {
      return searchWords.every((word) =>
        user?.Subject?.toLowerCase().includes(word.toLowerCase())
      );
    });

    setFilteredData(resultData);
  }, [search, users]);

  const columns = [
    {
      name: "Subject",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <img style={{ cursor: "default" }} src={ItemIcon} alt="Profile" />
          <span className="mx-2">{row?.Subject}</span>
        </div>
      ),
    },
    {
      name: "Ticket ID",
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: "Status",
      width: "170px",
      selector: (row) => (
        <div
          style={{ width: "130px", cursor: "default" }}
          className="text-center"
        >
          <div className={row?.Status}>{statusCode[row?.Status]}</div>
        </div>
      ),
      sortable: true,
    },
  ];

  const handleRowClick = (row) => {
    navigate("/support-issues/ticket-details", { state: { data: row } });
  };

  return (
    <div className="container-fluid align-left inventory-count">
      <div className="page-heading">
        <h4 className="align-left top_head">Support Issues</h4>
      </div>
      <DataTable
        columns={columns}
        data={search ? filteredData : users}
        pagination
        onRowClicked={(row) => handleRowClick(row)}
        subHeader
        subHeaderComponent={
          <div className="d-flex justify-content-between w-100 search_head">
            <div className="input-group w-50">
              <img src={SearchIcon} className="search-icon" alt="search" />

              <input
                type="text"
                placeholder="Search"
                className="form-control w-25"
                style={{
                  borderRadius: "40px",
                  paddingLeft: "40px",
                  height: "45px",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div>
              <div className="btn-custom scan-item">
                <Link to="/support-issues/add-support-issue">
                  <button>
                    <img src={AddIcon} alt="add Icon" /> Add New
                  </button>
                </Link>
              </div>
            </div>
          </div>
        }
        subHeaderAlign="left"
        progressPending={loading}
        pointerOnHover={true}
        progressComponent={
          <div className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              role="status"
              style={{ color: "skyblue" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      />
    </div>
  );
};

export default InventoryCounts;
