import axios from "axios";
import Constants from "../_helpers/constants";
import moment from "moment";
const CanoUserData = JSON.parse(localStorage.getItem("CanoUserDetails"));
const CanoID = CanoUserData?.["CanoID"] || "";
const Email = CanoUserData?.["email"].replaceAll("+", "%2B") || "";

//GET OPERATIONAL EMAIL
const APIv1GetOperatorInformationForEmail = async (
  enqueueSnackbar,
  CompanyCode
) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL +
        `/api/APIv1GetOperatorInformationForEmail?CanoID=${CanoID}&Email=${Email}`,
    });
    return response.data;
  } catch (err) {
    enqueueSnackbar("something went wrong", { variant: "error" });
    return err;
  }
};

// GetCompany list by email
const getCompanyListByEmail = async () => {
  try {
    const { data } = await axios.get(
      `${Constants.BASE_URL}/api/APIv1GetCompaniesForEmail?CanoID=${CanoID}&Email=${Email}`
    );
    return data;
  } catch (err) {
    return err;
  }
};

//GET WAREHOUSE LIST BY COMPANY
const getWarehouseListByCompany = async (enqueueSnackbar, CompanyCode) => {
  try {
    const UsersCompany = await axios.get(
      `${Constants.BASE_URL}/api/APIv1GetCompaniesForEmail?CanoID=${CanoID}&Email=${Email}`
    );
    const UserCompArry = UsersCompany.data.data.map(
      (user) => user.Company_Code
    );

    let AllWarehousesData = [];

    const fetchPromises = UserCompArry.map((companyCode) => {
      return axios.get(
        `${Constants.BASE_URL}/api/APIv1GetWarehouseListByCompany?CanoID=${CanoID}&CompanyCode=${companyCode}`
      );
    });

    const responses = await Promise.all(fetchPromises);
    for (let i = 0; i < UserCompArry.length; i++) {
      const data = responses[i].data;
      if (data.status) {
        AllWarehousesData.push(...data.data);
      }
    }
    return { data: AllWarehousesData };
  } catch (err) {
    return err;
  }
};
//GET DESTINATION WAREHOUSE LIST BY COMPANY
const getDestinationWarehouseListByCompanyByCode = async (
  enqueueSnackbar,
  newCompanyCode
) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL +
        `/api/APIv1GetWarehouseListByCompany?CanoID=${CanoID}&CompanyCode=${newCompanyCode}`,
    });
    return response.data;
  } catch (err) {
    enqueueSnackbar("something went wrong", { variant: "error" });
    return err;
  }
};

//GET WAREHOUSE LIST BY COMPANY
const getWarehouseListByCompanyByCode = async (
  enqueueSnackbar,
  warehouseCode,
  CompanyCode
) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL +
        `/api/APIv1GetWarehouseListByCompany?CanoID=${CanoID}&CompanyCode=${CompanyCode}&Warehouse=${warehouseCode}`,
    });
    return response.data;
  } catch (err) {
    enqueueSnackbar("something went wrong", { variant: "error" });
    return err;
  }
};
//GET ITEM LIST BY COMPANY
const getItemListByCompany = async (
  enqueueSnackbar,
  warehouseCode,
  scannedBarcode,
  CompanyCode
) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL +
        `/api/APIv1GetItemListByCompany?CanoID=${CanoID}&CompanyCode=${CompanyCode}&Warehouse=${warehouseCode}&Barcode=${scannedBarcode}`,
    });
    return response.data;
  } catch (err) {
    enqueueSnackbar("something went wrong", { variant: "error" });
    return err;
  }
};

const getItemListByCompanyByDescription = async (
  enqueueSnackbar,
  searchTerm,
  warehouseCode,
  searchBy,
  CompanyCode
) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL +
        `/api/APIv1GetItemListByCompany?CanoID=${CanoID}&CompanyCode=${CompanyCode}&${searchBy}=${searchTerm}&Warehouse=${warehouseCode}`,
    });
    return response.data;
  } catch (err) {
    enqueueSnackbar("something went wrong", { variant: "error" });
    return err;
  }
};

//ADD WAREHOUSE TO WAREHOUSE MOVE
const AddWarehouseMove = async (data, enqueueSnackbar, CompanyCode) => {
  try {
    const response = await axios({
      method: "post",
      url:
        Constants.BASE_URL +
        `/api/APIv1WarehouseToWarehouseTransfer?CanoID=${CanoID}&CompanyCode=${CompanyCode}`,
      data: data,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

//GET JOB LIST
const getJobList = async (enqueueSnackbar, CompanyCode) => {
  try {
    const response = await axios({
      // dataType: "json",
      method: "get",
      url:
        Constants.BASE_URL +
        `/api/APIv1GetJob?CanoID=${CanoID}&CompanyCode=${CompanyCode}`,
    });
    return response.data;
  } catch (err) {
    enqueueSnackbar("something went wrong", { variant: "error" });
    return err;
  }
};

//GET PHASE BY JOB NUMBER
const getPhasesByJob = async (JobNumber, enqueueSnackbar, CompanyCode) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL +
        `/api/APIv1GetPhase?CanoID=${CanoID}&CompanyCode=${CompanyCode}&JobNumber=${JobNumber}`,
    });
    return response.data;
  } catch (err) {
    enqueueSnackbar("something went wrong", { variant: "error" });
    return err;
  }
};

//ADD INVENTORY CHARGE
const AddInventoryCharge = async (data, enqueueSnackbar, CompanyCode) => {
  try {
    const response = await axios({
      method: "post",
      url:
        Constants.BASE_URL +
        `/api/APIv1SaveJobRequisition?Email=${Email}&CanoID=${CanoID}&CompanyCode=${CompanyCode}`,
      data: data,
    });
    return response.data;
  } catch (err) {
    enqueueSnackbar("something went wrong", { variant: "error" });
    return err;
  }
};

// ADD INVENTORY COUNT
const AddInventoryCount = async (data, enqueueSnackbar, CompanyCode) => {
  try {
    const response = await axios({
      method: "post",
      url:
        Constants.BASE_URL +
        `/api/APIv1InventoryAdjustment?CanoID=${CanoID}&CompanyCode=${CompanyCode}`,
      data: data,
    });
    return response.data;
  } catch (err) {
    enqueueSnackbar("something went wrong", { variant: "error" });
    return err;
  }
};
//EXISTING WAREHOUSE TO WAREHOUSE MOVE LIST
const getWarehouseMoveList = async (enqueueSnackbar, CanoID, CompanyCode) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL +
        `/api/APIv1GetWarehouseToWarehouseTransferHistory?CanoID=${CanoID}&CompanyCode=${CompanyCode}`,
    });
    return response.data;
  } catch (err) {
    const error = "something went wrong";

    console.log(error);
    return err;
  }
};

//EXISTING Inventory adjustments LIST
const getInventoryAdjustmentList = async (
  enqueueSnackbar,
  email,
  CanoID,
  CompanyCode
) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL +
        `/api/APIv1GetInventoryAdjustmentHistory?CanoID=${CanoID}&CompanyCode=${CompanyCode}&Email=${email}`,
    });
    return response.data;
  } catch (err) {
    const error = "something went wrong";
    console.log(error);

    return err;
  }
};

//EXISTING Inventory CHARGE LIST
const getExistingInventoryChargeList = async (
  enqueueSnackbar,
  email,
  CanoID,
  CompanyCode
) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL +
        `/api/APIv1GetJobRequisitionHistory?CanoID=${CanoID}&CompanyCode=${CompanyCode}&Email=${email}`,
    });
    return response.data;
  } catch (err) {
    const error = "something went wrong";

    console.log(error);
    return err;
  }
};
const getCanoAccountByCanoId = async (CanoID) => {
  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL + `/api/APIv1GetCanoAccount?CanoID=${CanoID}`,

      headers: {},
    });

    return response;
  } catch (err) {
    if (err.response.statusText === "Unauthorized") {
      localStorage.removeItem("currentUser");
      window.location.reload();
    }
  }
};

const groupByData = (array, key) => {
  try {
    return array.reduce((result, item) => {
      const dateValue = moment(item["DTS"], "M/D/YYYY h:mm A");
      result[dateValue] = (result[dateValue] || 0) + 1;
      return result;
    }, {});
  } catch (error) {
    return {}; // Return an empty object if an error occurs
  }
};

const InventoryService = {
  APIv1GetOperatorInformationForEmail,
  getWarehouseListByCompany,
  getItemListByCompany,
  AddWarehouseMove,
  getJobList,
  getPhasesByJob,
  AddInventoryCharge,
  AddInventoryCount,
  getWarehouseListByCompanyByCode,
  getItemListByCompanyByDescription,
  getCompanyListByEmail,
  getExistingInventoryChargeList,
  getInventoryAdjustmentList,
  getWarehouseMoveList,
  getCanoAccountByCanoId,
  groupByData,
  getDestinationWarehouseListByCompanyByCode,
};
export default InventoryService;
